<template>
	<v-list-group no-action>
		<template v-slot:activator>
			<v-list-item-icon class="mr-2 ml-1">
				<v-icon color="primary" small>{{ menuInfo.icon }}</v-icon>
			</v-list-item-icon>
			<v-list-item-title>{{ menuInfo.title }}</v-list-item-title>
		</template>
		<v-list-item
				v-for="(item,index) of menuInfo.children"
				:key="item.id"
				:disabled="idDisabled(item)"
				:to="{ path: item.path }"
				link
		>
			<v-list-item-title>{{ item.title }}</v-list-item-title>
		</v-list-item>
	</v-list-group>
</template>
<script>
export default {
	name: "MenuChildItem",
	props: {
		menuInfo: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			//当前登陆用户信息
			user: JSON.parse(localStorage.getItem("user")),
			permission: this.allowLink()
		}
	},
	methods: {
		//判断链接是否disabled
		allowLink() {
			let permissions = [
				{
					group: 'Admins',
					allow: [7, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33]
				},
				{group: '企业用户', allow: [7]},
				{group: 'CQC管理员（认证受理）', allow: [7, 22]},
				{group: 'CQC管理员（专家分配）', allow: [7, 23, 33]},
				{group: '专家（评价）', allow: [7, 32]},
				{group: '专家（评价-初审）', allow: [7, 25]},
				{group: '专家（评价-复审）', allow: [7, 24]},
				{group: '专家（评价-认定批准）', allow: [7, 26, 27]},
				{group: '专家（监督）', allow: [7, 28]},
				{group: '专家（监督-初审）', allow: [7, 29]},
				{group: '专家（监督-复审）', allow: [7, 30]},
				{group: '专家（监督-认定批准）', allow: [7, 31, 27]},
			]
			let group = JSON.parse(localStorage.getItem("user")).groups
			let permission_arr = []
			group.map(groupItem => {
				permissions.map(permissionItem => {
					if (permissionItem.group === groupItem) {
						permission_arr = permission_arr.concat(permissionItem.allow)
					}
				})
			})
			return Array.from(new Set(permission_arr))
		},
		//判断链接是否disable
		idDisabled(menu) {
			return (!this.permission.includes(menu.id)) && (menu.parent === 3)
		}
	}
};
</script>
