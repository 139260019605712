<template>
  <div>
    <v-app-bar app clipped-left dark dense elevate-on-scroll>
      <v-app-bar-nav-icon @click="handleDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="text-h6" style="width: 300px;"
        >吉林科学出版社图书管理平台</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <!-- <v-text-field append-outer-icon="mdi-magnify" class="mt-5" dense flat placeholder="搜索" solo></v-text-field> -->
<!--      <v-menu offset-y>-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <v-btn v-bind="attrs" v-on="on" icon>-->
<!--            <v-badge color="red" content="6" offset-y="13" offset-x="10">-->
<!--              <v-icon>mdi-email</v-icon>-->
<!--            </v-badge>-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <v-list>-->
<!--          <v-list-item>-->
<!--            <v-list-item-title>-->
<!--              待处理消息（ 6 ）-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->
<!--          <v-list-item>-->
<!--            <v-list-item-title>-->
<!--              历史消息-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-menu>-->
      <v-divider vertical class="mx-2"></v-divider>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon dark class="mr-2">mdi-account-circle</v-icon>
            <strong style="line-height:48px">{{ username }}</strong>
          </v-btn>
        </template>
        <v-list>
<!--          <v-list-item @click="toUserHome">-->
<!--            <v-list-item-title>-->
<!--              个人中心-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->
          <v-list-item @click="logout">
            <v-list-item-title>
              退出
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- chenxi 2020808
        根据 Vuetify 官方文档，app 属性表示此组件用于整体布局，用到了 absolute 定位，必须放在 <v-main> 标签外面；
        clipped 属性表示本 drawer 组件紧贴在 app-bar 组件下方，不会覆盖 app-bar. 而 bottom 属性表示在移动端时，菜单从下方弹出。
        
        经过测试，expand-on-hover 属性和 mini-variant 属性有冲突，不兼容。如果启用 expand-on-hover, 则左上角的按钮会失效。在 PC 端可以通过鼠标
        滑入滑出来 展开/收起 菜单，但是无法通过左上角按钮 展开/收起 菜单。结果是，在移动端时由于不存在 expand-on-hover 操作，导致无法呼出菜单。
        因此在这里，我没有使用 expand-on-hover, 而是直接使用 @mouseover.native, @mouseout.native 事件来处理鼠标的滑入滑出动作。
     -->
    <v-navigation-drawer
      :mini-variant="mini"
      app
      clipped
      mini-variant-width="56"
      mobile-breakpoint="600"
      permanent
      width="195"
    >
      <v-list v-slimscroll="slimScrollLightOption" dense nav>
        <div v-for="item of menuData" :key="item.id">
          <menu-item
            v-if="item.children == null || item.children.length <= 0"
            :menuInfo="item"
          />
          <menu-child-item
            v-if="item.children && item.children.length > 0"
            :menuInfo="item"
          />
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import menuData from "./menuData";
import MenuItem from "./menu-item";
import MenuChildItem from "./menu-childitem";
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    source: String,
  },
  data: () => ({
    menuData,
    mini: false,
    avatar: "",
    slimScrollLightOption: {
      height: "100%",
      color: "#ddd",
      size: "5px",
    },
    username: JSON.parse(localStorage.getItem("user")).username,
  }),
  computed: {
    ...mapState({
      menu: (state) => state.menu,
    }),
  },
  methods: {
    ...mapMutations(["setUser"]),
    handleDrawer() {
      this.mini = !this.mini;
    },
    logout() {
      this.setUser(null);
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("menu");
      this.$router.push({ name: "login" });
    },
    toUserHome() {
      this.$router.push({ name: "user_home" });
    },
  },

  async mounted() {},

  components: {
    MenuItem,
    MenuChildItem,
  },
};
</script>
<style></style>
