/*
 * @Description:
 * @version: V1.0
 * @Author: 张宇童
 * @Date: 2020/9/6 14:00
 * @LastEditors: 张宇童
 * @LastEditTime: 2020/9/6 14:00
 */
export default [
  // {
  //   id: 1,
  //   name: "首页",
  //   title:'首页',
  //   path: "/admin/index",
  //   icon: "mdi-home",
  // },
  {
    id: 2,
    name: "图书管理",
    title:"图书管理",
    path: "/admin/books",
    icon: "mdi-view-dashboard",
  },
  {
    id: 3,
    name: "库存管理",
    title:"库存管理",
    path: "/admin/inventories/",
    icon: "mdi-account",
  },
  {
    id: 4,
    name: "顾客管理",
    title:"顾客管理",
    path: "/admin/customers",
    icon: "mdi-account",
  },
  {
    id: 5,
    name: "订单管理",
    title:"订单管理",
    path: "/admin/orders",
    icon: "mdi-cog",
  },
  {
    id:6,
    name: "类型管理",
    title:"类型管理",
    path:"/admin/type",
    icon: "mdi-book-edit"
  },
  {
    id:7,
    name:'图片管理',
    title: "图片管理",
    path: "/admin/pic",
    icon:"mdi-image"
  }
  // {
  //   id: 6,
  //   name: "售后管理",
  //   title:"售后管理",
  //   path: "/admin/after_sales",
  //   icon: "mdi-cog",
  // }
];
